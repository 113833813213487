<script>
import TextInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/TextInput.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import Dropdown from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Dropdown.vue";
import GenericPage from "@/components/Global/Molecules/GenericPage.vue";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "@/components/Settings/Molecules/SettingsSection.vue";
import SettingsForm from "@/components/Settings/Molecules/SettingsForm.vue";
import SettingsFooter from "@/components/Settings/Molecules/SettingsFooter.vue";
import WidgetPreview from "@/components/Widgets/Molecules/WidgetPreview.vue";
import FullSizeWidgetPopup from "@/components/Global/Atoms/FullSizeWidgetPopup.vue";
import { mapGetters } from "vuex";
import * as copy from "copy-to-clipboard";
import generateWidget from "@/graphql/widgets/generateWidget.gql";
import WidgetEmbedCode from "@/components/Widgets/Atoms/WidgetEmbedCode.vue";
import { scrollToFirstError } from "@/validation";

export default {
    name: "WidgetSettings",
    components: {
        GenericPage,
        SettingsBlock,
        SettingsSection,
        SettingsForm,
        SettingsFooter,
        Dropdown,
        TextInput,
        Button,
        WidgetPreview,
        FullSizeWidgetPopup,
        WidgetEmbedCode,
    },
    data() {
        return {
            dataSources: {
                styles: [
                    {
                        value: "mini",
                        displayValue: this.$i18n.t(
                            "widget_settings.form.widget_block.inputs.style.values.mini"
                        ),
                    },
                    {
                        value: "medium",
                        displayValue: this.$i18n.t(
                            "widget_settings.form.widget_block.inputs.style.values.medium"
                        ),
                    },
                ],
            },
            settings: {
                style: "mini",
                reviewsAmount: 0,
            },
            openFullSizePopup: false,
            modalPreview: null,
            copyButtonLabel: "customer_portal_widgets_showcase_embed_code_copy",
            embedCodeContent: "",
            previewSettings: {
                previewFilename: "classic--mini--0-reviews.html",
                id: "classic-widget",
                name: "",
                type: "Review",
                descriptionTranslationKey: "",
                options: {
                    baseUrl: null,
                    scopeData: null,
                },
            },
            readyForCopy: true,
        };
    },
    methods: {
        scrollToFirstError,
        /**
         * Event handler for "Cancel" button.
         */
        cancelForm() {
            this.$router.push("/widgets");
        },

        /**
         * Handler for opening preview of the selected widget in a modal.
         * @param preview
         */
        openFullSizePreview(preview) {
            this.modalPreview = preview.preview;
            this.openFullSizePopup = true;
        },

        /**
         * Handler for closing preview of the selected widget in a modal.
         */
        closeFullSizePreview() {
            this.openFullSizePopup = false;
        },

        /**
         * Handler for generating the embed code for the user.
         * @returns {Promise<void>}
         */
        async copyCode() {
            const self = this;
            self.embedCodeContent = await this.getEmbedCode(
                self.settings.style,
                self.settings.reviewsAmount
            );

            if (self.embedCodeContent === null) {
                return;
            }

            // Copy it to the clipboard:
            copy(self.embedCodeContent);

            await this.$store.dispatch("pushNotification", {
                type: "success",
                title: this.$t("cp__general__copied"),
                message: this.$t("cp__general__copied__message"),
            });

            // Update button label.
            this.copyButtonLabel = "customer_portal_widgets_showcase_embed_code_copied";
        },

        /**
         * @returns {Promise<string | null>}
         */
        async getEmbedCode(size, reviews) {
            try {
                const variables = {
                    language: this.currentLanguage,
                    reviews,
                    size,
                    type: "classic",
                    divisionUuid: this.activeAccount.externalUuid,
                };

                const { data } = await this.$apollo.mutate({
                    mutation: generateWidget,
                    variables: { input: variables },
                });

                // atob is the browser-native base64 decode operation
                return Promise.resolve(atob(data.generateWidget.embedCode));
            } catch (errors) {
                this.$nextTick(() => {
                    this.scrollToFirstError(this.$el);
                });
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__pn__config_invitation__error__title"),
                    message: this.$t("cp__generic__error"),
                });

                // eslint-disable-next-line no-console
                console.error(errors);

                return Promise.resolve(null);
            }
        },

        async checkIfReady() {
            const self = this;
            const ds = this.dataSources.styles;
            const currStyle = this.settings.style;
            const hasStyle = ds.find((item) => item.value === currStyle) !== undefined;
            const honorMin = this.settings.reviewsAmount >= 0;
            const honorMax = this.settings.reviewsAmount <= 2;
            const isNan = Number.isNaN(this.settings.reviewsAmount);
            const hasValidReviewsAmount = !isNan && honorMin && honorMax;

            if (self.embedCodeContent !== "") {
                copy(" ");
            }

            self.embedCodeContent = "";
            self.copyButtonLabel = "customer_portal_widgets_showcase_embed_code_copy";

            if (hasStyle && hasValidReviewsAmount) {
                self.previewSettings.previewFilename = `classic--${currStyle}--${this.settings.reviewsAmount}-reviews.html`;
                self.readyForCopy = true;
            } else {
                self.readyForCopy = false;
            }
        },

        closeEmbedCode() {
            this.copyButtonLabel = "customer_portal_widgets_showcase_embed_code_copy";
            this.checkIfReady();
        },
    },
    watch: {
        settings: {
            deep: true,
            handler: "checkIfReady",
        },
    },
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            currentLanguage: "LanguageStore/getLanguage",
            language: "LanguageStore/getLanguage",
        }),
    },
    async created() {
        // Run initial check.
        await this.checkIfReady();
    },
};
</script>

<template>
    <GenericPage
        class="widget_settings"
        :headerProps="{
            title: $t('widget_preview_name_classic'),
            subtitle: $t('widget_settings.description'),
            disableBackButton: false,
            backButtonTo: { path: '/widgets' },
        }"
    >
        <SettingsForm class="settings_form">
            <SettingsBlock
                class="settings_block"
                :title="$t('widget_settings.form.widget_block.title')"
            >
                <SettingsSection
                    class="communications_settings_fieldset"
                    :label="$t('widget_settings.form.widget_block.inputs.style.title')"
                >
                    <div class="body2">
                        {{ $t("widget_settings.form.widget_block.inputs.style.description") }}
                    </div>

                    <Dropdown
                        v-model="settings.style"
                        :searchAble="false"
                        :placeholder="
                            $t('widget_settings.form.widget_block.inputs.style.placeholder')
                        "
                        :options="dataSources.styles"
                    />
                </SettingsSection>

                <SettingsSection
                    class="communications_settings_fieldset"
                    :label="$t('widget_settings.form.widget_block.inputs.reviewsAmount.title')"
                >
                    <div class="body2">
                        {{
                            $t("widget_settings.form.widget_block.inputs.reviewsAmount.description")
                        }}
                    </div>

                    <TextInput
                        v-model="settings.reviewsAmount"
                        :required="true"
                        :numeric="true"
                        :min="0"
                        :max="2"
                        class="reviews-amount"
                        :minMessage="(min) => $t('cp_min_required_input_message', { min: min })"
                        :maxMessage="(max) => $t('cp_max_required_input_message', { max: max })"
                    />
                </SettingsSection>
            </SettingsBlock>

            <SettingsBlock
                class="settings_block preview_block"
                :title="$t('widget_settings.form.widget_preview.title')"
            >
                <SettingsSection maxWidth="none" label="">
                    <template v-if="!readyForCopy">
                        <h2 class="body1">
                            {{ $t("cp__customise_widgets__preview_error") }}
                        </h2>
                    </template>

                    <WidgetPreview
                        v-if="!embedCodeContent && readyForCopy"
                        :widget="previewSettings"
                        @openFullSize="openFullSizePreview"
                        :showCopyButton="false"
                        :showHeader="false"
                        :enablePopup="false"
                    />

                    <FullSizeWidgetPopup
                        v-if="openFullSizePopup"
                        :preview="modalPreview"
                        :previewIsVideo="false"
                        @close="closeFullSizePreview"
                    />

                    <WidgetEmbedCode
                        class="widget_embed_code"
                        v-if="readyForCopy && embedCodeContent"
                        :embedCode="embedCodeContent"
                        :autoSelect="true"
                        @close="closeEmbedCode"
                    />
                </SettingsSection>
            </SettingsBlock>
        </SettingsForm>
        <SettingsFooter>
            <Button @click.native="cancelForm" buttonStyle="secondary">
                {{ $t("Cancel") }}
            </Button>

            <Button @click.native="copyCode" :disabled="!readyForCopy">
                {{ $t(copyButtonLabel) }}
            </Button>
        </SettingsFooter>
    </GenericPage>
</template>

<style lang="scss" scoped>
@import "../../style_variables/style_variables.scss";
@import "~include-media";

.settings_form {
    width: 100%;
    max-width: 950px;
}

.settings_block {
    width: 100%;
    margin-top: 40px;

    &:first-of-type {
        margin-top: 40px;
    }

    &:last-of-type {
        margin-bottom: 170px;
    }
}

.form_footer__controls_container {
    button {
        margin-left: 10px;
    }
}

::v-deep {
    .reviews-amount {
        .text_input {
            width: 70px;
        }

        .text_input__controls {
            padding-right: 0;
        }

        .validation-message {
            margin-left: 0;
        }
    }

    .widget_showcase {
        padding: 0;
        border: none;
        height: auto;
        width: auto;

        .widget_showcase_display_holder {
            padding: 0;

            .widget_showcase_display {
                position: static;
            }
        }
    }
}

.body2 {
    display: inline-block;
    margin-bottom: 16px;
}

.preview_block {
    @media (max-width: 500px) {
        padding: 0;
        ::v-deep .settings_block__header {
            padding: 24px;
        }
    }
}
</style>
